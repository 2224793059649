import React from "react"
import getSymbolFromCurrency from 'currency-symbol-map'
import {VictoryBar, VictoryChart, VictoryAxis, VictoryContainer, VictoryTheme} from 'victory'

type acqPropos = {
    market: any
}

const Acquiring = ({market}: acqPropos) => {
    let currentCurrency;
    market?.condition?.devices?.billvalidator.data.version.currency === 'RUR'
        ? currentCurrency = 'RUB' 
        : currentCurrency = market?.condition?.devices?.billvalidator.data.version.currency;

    const currency = getSymbolFromCurrency(currentCurrency)

    let colors = ['#2B3E50', '#4F2B50', '#503D2B', '#2B502B']
    let data: any[] | undefined = []
    let tickFormat: any[] | undefined = []
    let totalSum: number = 0
    let tickValues = [0, 1]

    if(!market?.hasOwnProperty('stats')) {
        return <></>
        /*return (
            <div className="details_block details_block--sm">
                <h4 className="text-center details__header">Эквайринг</h4>

                <p className="text-center text--bold text--blue">Нет данных</p>
            </div>
        )*/
    }

    ['daily_sbp', 'daily_terminal'].forEach((element: any, index: number) => {
        let amount = parseFloat(market?.stats[element])
        if (!isNaN(amount) && amount>0) {
            data?.push({
                fill: colors[index],
                label: `${amount} ${currency}`,
                count: amount,
            })
            totalSum += amount
            if(element === 'daily_terminal') {
                tickFormat?.push('Терминал')
            }
            if(element === 'daily_sbp') {
                tickFormat?.push('СБП')
            }
        }
    })

    if (data.length === 0) {
        return <></>
    }
    return (
        <div className="details_block details_block--sm">
            <h4 className="text-center details__header">Эквайринг</h4>

            <VictoryChart
                domainPadding={{ x: 15 }}
                theme={VictoryTheme.material}
                containerComponent={
                    <VictoryContainer
                        className="myChart"
                    />
                }
            >
                <VictoryAxis
                    tickValues={tickValues}
                    tickFormat={tickFormat}
                    style={{
                        tickLabels: {
                            fontSize: 20,
                        }
                    }}
                />
                <VictoryBar
                    data={data}
                    x="quarter"
                    y="count"
                    barRatio={1.2}
                    style={{
                        data: {
                            fill: ({ datum }) => datum.fill,
                            stroke: ({ datum }) => datum.fill,
                            fillOpacity: 0.7,
                            strokeWidth: 3
                        },
                        labels: {
                            fontSize: 20
                        }
                    }}
                />
            </VictoryChart>

            <p className="text-center text--bold text--blue">Итого: {+totalSum.toFixed(2)} {currency}</p>
        </div>
    )
}

export default Acquiring
