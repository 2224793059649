import React from "react"
import getSymbolFromCurrency from 'currency-symbol-map'
import {VictoryBar, VictoryChart, VictoryAxis, VictoryContainer, VictoryTheme} from 'victory'

type billsProps = {
    market: any
}

const Bills = ({market}: billsProps) => {
    let currentCurrency;
    market?.condition?.devices?.billvalidator.data.version.currency === 'RUR'
        ? currentCurrency = 'RUB' 
        : currentCurrency = market?.condition?.devices?.billvalidator.data.version.currency;

    const currency = getSymbolFromCurrency(currentCurrency)


    const colors = ['#2B3E50', '#4F2B50', '#503D2B', '#2B502B']
    const data: any[] | undefined = []
    let tickValues: any[] | undefined = []
    let tickFormat: any[] | undefined = []
    let cashSum = 0
    let index = 0

    Object.keys(Object.assign({}, market?.condition?.devices?.billvalidator.data.level)).forEach((note) => {
        let countNotes = market?.condition?.devices?.billvalidator.data.level[note]
        if (countNotes > 0) {
            data.push({
                quarter: index + 1,
                count: countNotes,
                fill: colors[index],
                label: `${countNotes} шт.`
            })

            let value = parseInt(note) / 100
            cashSum += value * countNotes

            tickValues?.push(index+1)
            tickFormat?.push(`${value} руб`)
            index++
        }
    })

    if (data.length === 0) {
        return <></>
    }

    return (
        <div className="details_block details_block--sm">
            <h4 className="text-center details__header">Купюры</h4>

            <VictoryChart
                domainPadding={{ x: 15 }}
                theme={VictoryTheme.material}
                containerComponent={
                    <VictoryContainer
                        className="myChart"
                    />
                }
            >
                <VictoryAxis
                    tickValues={tickValues}
                    tickFormat={tickFormat}
                    style={{
                        tickLabels: {
                            fontSize: 20
                        }
                    }}
                />
                <VictoryBar
                    data={data}
                    x="quarter"
                    y="count"
                    barRatio={1.2}
                    style={{
                        data: {
                            fill: ({ datum }) => datum.fill,
                            stroke: ({ datum }) => datum.fill,
                            fillOpacity: 0.7,
                            strokeWidth: 3
                        },
                        labels: {
                            fontSize: 20
                        }
                    }}
                />
            </VictoryChart>

            <p className="text-center text--bold text--blue">Итого: {cashSum} {currency}</p>
        </div>
    )
}

export default Bills;
