import React, {useEffect, useState} from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../../../RootStateContext"
import {Col, Row, Card, Form, Button, Accordion} from "react-bootstrap"
import PriceListCard from './priceListCard'

const Pricelists = observer(() => {
    const [name, setName] = useState('')
    const [water1, setWater1] = useState('')
    const [water2, setWater2] = useState('')
    const [water3, setWater3] = useState('')
    const [waterPrice, setWaterPrice] = useState('')
    const [pour, setPour] = useState(false)
    const [bottlePrice, setBottlePrice] = useState('')
    const [cardPrice, setCardPrice] = useState('')
    const [corkPrice, setCorkPrice] = useState('')
    const [ errors, setErrors ] = useState({
        name: '',
        water1: '',
        water2: '',
        water3: '',
        waterPrice: '',
        bottlePrice: '',
        cardPrice: '',
        corkPrice: ''
    })

    const {priceListStore} = useRootStore()
    const {priceList, getPriceLists, priceLists} = priceListStore

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        const newErrors = findFormErrors()

        // if ( Object.keys(newErrors).length > 0 ) {
        if ( !Object.values(newErrors).every(x => (x === null || x === '')) ) {
            
            setErrors(newErrors)

            console.log('errors', errors);
        } else {
            console.log('none errors');
            
            priceList(name, +water1, +water2, +water3, +waterPrice, +bottlePrice, +corkPrice, +cardPrice, pour, true)

            setErrors({
                name: '',
                water1: '',
                water2: '',
                water3: '',
                waterPrice: '',
                bottlePrice: '',
                cardPrice: '',
                corkPrice: ''
            })
        }
    }

    const findFormErrors = () => {
        const newErrors = {
            name: '',
            water1: '',
            water2: '',
            water3: '',
            waterPrice: '',
            bottlePrice: '',
            cardPrice: '',
            corkPrice: ''
        }
        setErrors(newErrors)
        if ( !name || name === '' ) newErrors.name = 'Имя не может быть пустым'
        
        if (isNaN(+water1)) {
            newErrors.water1 = 'Введите число'
        } else if ( !water1 || +water1 > 20 || +water1 < 1 ) {
            newErrors.water1 = 'От 1 до 20л'
        }

        if(isNaN(+water2)) {
            newErrors.water2 = 'Введите число'
        } else if ( !water2 || +water2 > 20 || +water2 < 1 ) {
            newErrors.water2 = 'От 1 до 20л'
        }

        if(isNaN(+water3)) {
            newErrors.water3 = 'Введите число'
        } else if ( !water3 || +water3 > 20 || +water3 < 1 ) {
            newErrors.water3 = 'От 1 до 20л'
        }

        if(isNaN(+waterPrice)) {
            newErrors.waterPrice = 'Введите число'
        } else if ( !waterPrice || +waterPrice > 300 || +waterPrice < 1 ) {
            newErrors.waterPrice = 'От 1 до 300'
        }

        if(isNaN(+bottlePrice)) {
            newErrors.bottlePrice = 'Введите число'
        } else if ( !bottlePrice || +bottlePrice > 200 || +bottlePrice < 1 ) {
            newErrors.bottlePrice = 'От 1 до 200'
        }

        if(isNaN(+cardPrice)) {
            newErrors.cardPrice = 'Введите число'
        } else if ( !cardPrice || +cardPrice > 200 || +cardPrice < 1 ) {
            newErrors.cardPrice = 'От 1 до 200'
        }

        if(isNaN(+corkPrice)) {
            newErrors.corkPrice = 'Введите число'
        } else if ( !corkPrice || +corkPrice > 200 || +corkPrice < 1 ) {
            newErrors.corkPrice = 'От 1 до 200'
        }

        return newErrors
    }

    useEffect(() => {
        getPriceLists()
    }, [])

    return (
        <>
            <Card>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridPriceListName">
                                <Form.Label>Название</Form.Label>
                                <Form.Control value={name} onChange={e => setName(e.target.value)} required isInvalid={ !!errors.name }/>
                                <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridPriceListWater1">
                                <Form.Label>Вода (объем 1)</Form.Label>
                                <Form.Control value={water1} onChange={e => setWater1(e.target.value)} required isInvalid={ !!errors.water1 }/>
                                <Form.Control.Feedback type='invalid'>{errors.water1}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridPriceListWater2">
                                <Form.Label>Вода (объем 2)</Form.Label>
                                <Form.Control value={water2} onChange={e => setWater2(e.target.value)} required isInvalid={ !!errors.water2 }/>
                                <Form.Control.Feedback type='invalid'>{errors.water2}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridPriceListWater3">
                                <Form.Label>Вода (объем 3)</Form.Label>
                                <Form.Control value={water3} onChange={e => setWater3(e.target.value)} required isInvalid={ !!errors.water3 }/>
                                <Form.Control.Feedback type='invalid'>{errors.water3}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridPriceListPrice">
                                <Form.Label>Вода за 1л</Form.Label>
                                <Form.Control value={waterPrice} onChange={e => setWaterPrice(e.target.value)} required isInvalid={ !!errors.waterPrice }/>
                                <Form.Control.Feedback type='invalid'>{errors.waterPrice}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridPriceListPour">
                                <Form.Label>Свободный налив</Form.Label>
                                <Form.Check type="checkbox" checked={pour} onChange={e => setPour(e.target.checked)}/>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridPriceListBottlePrice">
                                <Form.Label>Тара, руб</Form.Label>
                                <Form.Control value={bottlePrice} onChange={e => setBottlePrice(e.target.value)} required isInvalid={ !!errors.bottlePrice }/>
                                <Form.Control.Feedback type='invalid'>{errors.bottlePrice}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridPriceListCardPrice">
                                <Form.Label>Карта, руб</Form.Label>
                                <Form.Control value={cardPrice} onChange={e => setCardPrice(e.target.value)} required isInvalid={ !!errors.cardPrice }/>
                                <Form.Control.Feedback type='invalid'>{errors.cardPrice}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridPriceListCorkPrice">
                                <Form.Label>Пробка, руб</Form.Label>
                                <Form.Control value={corkPrice} onChange={e => setCorkPrice(e.target.value)} required isInvalid={ !!errors.corkPrice }/>
                                <Form.Control.Feedback type='invalid'>{errors.corkPrice}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Button variant="primary" type="submit">
                            Добавить
                        </Button>
                    </Form>
                </Card.Body>
            </Card>

            {priceLists?.map(list => {
                return (
                    <PriceListCard 
                        data={list}
                        key={list.id}
                    />
                )
            })}
        </>
    )
})

export default Pricelists