import React, {useEffect, useState} from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../../../RootStateContext"
import {Col, Row, Card, Form, Button} from "react-bootstrap"
import PersonalizationCard from "./PersonalizationCard";


const Personalization = observer(() => {
    const {configurationStore} = useRootStore()
    const {personalizations, loadPersonalizations, createPersonalisation} = configurationStore

    useEffect(()=> {
        loadPersonalizations()
    }, [])

    const [name, setName] = useState('')
    const [file, setFile] = useState()
    const [fileName, setFileName] = useState('')
    const [extError, setExtError] = useState('')
    const [sizeError, setSizeError] = useState('')

    const onChangeFile = (e:any) => {
        setExtError('')
        setSizeError('')

        if (e.target.files[0].type !== 'image/png') {
            setExtError('Изображение должно именть формат PNG.')
        }

        if (e.target.files[0].size / 1024 >= 500) {
            setSizeError('Размер файла не должен превышать 500кб.')
        }

        if(extError || sizeError) {
            return
        } else {
            let files = e.target.files;
            setFile(files[0])
            setFileName(files[0].name)
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        createPersonalisation(name, file!)
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <Form autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label htmlFor="newPersonalizationName">Название</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="newPersonalizationName"
                                    size="sm"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label htmlFor="newPersonalizationLogo">Логотип</Form.Label>
                                <Form.Control
                                    type="file"
                                    id="newPersonalizationLogo"
                                    size="sm"
                                    onChange={(e) => onChangeFile(e)}
                                />
                                <Form.Text className="text-muted">
                                    В формате PNG, не более 500кб.
                                </Form.Text>
                            </Form.Group>
                        </Row>
                        
                        <Row>
                            <Col sm={2}>
                                <Button 
                                    variant="primary" 
                                    type="submit" 
                                    disabled={(extError.length > 0) || (sizeError.length > 0) || (name.length === 0) || (fileName.length === 0)}
                                >
                                    Добавить
                                </Button>
                            </Col>
                            <Col>
                                <div className="errors" style={{fontSize: '13px', color: 'red'}}>
                                    {extError}<br/>{sizeError}    
                                </div>                    
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            {personalizations?.map( p => <PersonalizationCard key={p.id} personalization={p}/>)}
        </>
    )
})

export default Personalization