import api from './api'

const config = {
     headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
};

export const getMarkets = async () => {
    const response = await api.get(
        'market', 
        {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
    )
    return response.data
}

export const updateMarket = async(id: number, settings: any) => {
    const response = await api.put(
        `market/${id}`,
        settings,
        config
    )

    return response.data
}

export const getMarketGroups = async () => {
    const response = await api.get('market/group', config)
    return response.data
}

export const createMarketGroup = async (name: string) => {
    const response = await api.post('market/group', {name},  config)
    return response.data
}

export const updateMarketGroup = async (id: number, name: string) => {
    const response = await api.put(`market/group/${id}`, {name}, config)
    return response.data
}

export const deleteMarketGroup = async (id: number) => {
    const response = await api.delete(`market/group/${id}`, config)
    return response.data
}

export const sendComand = async (marketId: number, command: string) => {
    const response = await api.post(
        `market/${marketId}/command/${command}`,
        {}, 
        {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
    )
    return response.data
}

export const getSells = async (marketId: number) => {
    const response = await api.get(
        `market/${marketId}/selling`, 
        {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
    )
    return response.data
}

export const getPurchases = async (marketId: number) => {
    const response = await api.get(
        `market/${marketId}/purchase`,
        {
            headers: {Authorization: `Bearer ${localStorage.getItem('authToken')}`}
        }
    )

    return response.data
}

export const getCollections = async (marketId: number) => {
    const response = await api.get(
        `market/${marketId}/collection`, 
        {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
    )
    return response.data
}

export const getQr = async (marketId: number) => {
    const response = await api.get(
        `market/${marketId}/qr`,
        {
            headers: {Authorization: `Bearer ${localStorage.getItem('authToken')}`}
        }
    )
    return response.data
}

export const getEvents = async (marketId: number) => {
    const response = await api.get(
        `market/${marketId}/event`, 
        {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
    )
    return response.data
}

export const getMesages = async (marketId: number) => {
    const response = await api.get(
        `market/${marketId}/message`,
        {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
    )

    return response.data
}

export const deactivateMessages = async (marketId: number, messageId?: number) => {
    const response = await api.post(
        `market/${marketId}/message/deactivate/${messageId??''}`,
        {},
        {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
    )
}

export const putEvents = async (marketId: number, eventId: number) => {
    const response = await api.put(
        `market/${marketId}/event/${eventId}`, 
        {status: "inactive"},
        {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
        }
    )
    return response.data
}


export const putAllEvents = async (marketId: number, eventsId: number[]) => {
    const response = await api.put(
        `market/${marketId}/event`, 
        {status: "inactive"},
        {
            headers: {Authorization: `Bearer ${localStorage.getItem('authToken')}`},
            params: {ids: eventsId}
        }
    )
    return response.data
}