import React from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../../RootStateContext"

const Qr = observer(() => {
    const {marketsStore} = useRootStore()
    const {currentMarketQr} = marketsStore

    return (
        <div className="details_block details_block--qr">
            <h4 className="text-center details__header mb-3">Доступ</h4>
            <img src={'data:image/png;base64,' + currentMarketQr} alt="" />
            <p>Поднесите QR-код к считывателю</p>
        </div>
    )
})

export default Qr