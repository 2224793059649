import React, {useState} from "react"
import {Form, Button, Accordion} from "react-bootstrap"


type marketProps = {
    market: any,
    groups: any,
    entities: any,
    priceLists: any,
    personalizations: any,
    cities: any,
    update: any
}
const MarketCard = ({market, groups, entities, priceLists, personalizations, cities, update}:marketProps) => {

    const [isDirty, setDirty] = useState(false)
    const [priceList, setPriceList] = useState(market.pricelist_id)
    const [personalization, setPersonalization] = useState(market.ui_template_id)
    const [group, setGroup] = useState(market.group)
    const [address, setAddress] = useState(market.address)
    const [city, setCity] = useState(market.city_id)
    const [number, setNumber] = useState(market.number)
    const [entity, setEntity] = useState(market.entity?.id)
    const [validated, setValidated] = useState(false);



    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        e.stopPropagation()

        const form = e.currentTarget as HTMLFormElement
        const isValid = form.checkValidity()
        setValidated(true)
        if (isValid) {
            update(market.id, {
                priceList,
                personalization,
                group,
                address,
                city,
                number,
                entity
            })
        }

    }

    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>{market.address}</Accordion.Header>
                <Accordion.Body>
                    <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>Прайс-лист</Form.Label>
                            <Form.Select
                                value={priceList}
                                onChange={e => {
                                    setPriceList(parseInt(e.target.value))
                                    setDirty(true)
                                }}>
                                <option disabled>выберите прайс-лист</option>
                                {priceLists?.map( (p:any) => <option key={p.id} value={p.id}>{p.name}</option>)}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Пресонализация</Form.Label>
                            <Form.Select
                                value={personalization}
                                onChange={e => {
                                    setPersonalization(parseInt(e.target.value))
                                    setDirty(true)
                                }}>
                                <option disabled>Выберите персонализацию</option>
                                {personalizations?.map( (p:any) => <option key={p.id} value={p.id}>{p.name}</option>)}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Группа</Form.Label>
                            <Form.Select
                                value={group}
                                onChange={e => {
                                    setGroup(parseInt(e.target.value))
                                    setDirty(true)
                                }}>
                                <option>Без группы</option>
                                {groups?.map( (g:any) => <option key={g.id} value={g.id}>{g.name}</option>)}
                                </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Адрес</Form.Label>
                            <Form.Control
                                value={address}
                                onChange={e => {
                                    setAddress(e.target.value)
                                    setDirty(true)
                                }}
                                required/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Город</Form.Label>
                            <Form.Select
                                value={city}
                                onChange={e => {
                                    setCity(parseInt(e.target.value))
                                    setDirty(true)
                                }}>
                                <option disabled>Выберите город</option>
                                {cities?.map( (g:any) => <option key={g.id} value={g.id}>{g.name}</option>)}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Порядковый номер</Form.Label>
                            <Form.Control
                                value={number}
                                type="number"
                                onChange={e => {
                                    setNumber(parseInt(e.target.value))
                                    setDirty(true)
                                }}
                                required/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Юр.лицо</Form.Label>
                            <Form.Select
                                value={entity}
                                onChange={e => {
                                    setEntity(parseInt(e.target.value))
                                    setDirty(true)
                                }}
                                required>
                                <option disabled>Выберите юр. лицо</option>
                                {entities?.map( (e: any) => <option key={e.id} value={e.id}>{e.name}</option>)}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Button type="submit" variant="primary" disabled={!isDirty}>Сохранить</Button>
                        </Form.Group>
                    </Form>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>

    )
}

export default MarketCard