import React, {CSSProperties } from 'react'

const Loader = (props: {size?: number})  => {
    let {size} = props
    if (size === undefined) {
        size = 80
    }
    const divStyles: CSSProperties = {
        width: size,
        height: size
    }
    return <div style={divStyles} className="ldsDualRing"></div>
}

export default Loader