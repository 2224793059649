import React, {useState} from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../../../RootStateContext"
import {Col, Row, Accordion, Card, Form, Button} from "react-bootstrap"
import {Entity} from "../../../../stores/FirmStore";

type FirmProps = {entity: Entity}

const EntityCard = observer(({entity}: FirmProps) => {
    const {firmsStore} = useRootStore()
    const {updateEntity, deleteEntity} = firmsStore


    const [name, setName] = useState(entity.name)
    const [inn, setInn] = useState(entity.inn)
    const [toCanEmail, setToCanEmail] = useState(entity.toCan?.email)
    const [toCanSecretKey, setToCanSecretKey] = useState(entity.toCan?.secret_key)
    const [sbpLogin, setSbpLogin] = useState(entity.sbp?.login)
    const [sbpPassword, setSbpPassword] = useState(entity.sbp?.password)
    const [sbpOrgId, setSbpOrgId] = useState(entity.sbp?.orgId)
    const [sbpElplatPhone, setSbpElplatPhone] = useState(entity.sbp?.elplatPhone)

    const [validated, setValidated] = useState(false);

    const isSbpRequired = (): boolean => {
        if (sbpLogin || sbpPassword || sbpElplatPhone || sbpOrgId) {
            return true
        }
        return false
    }

    const isToCanRequired = (): boolean => {
        if (toCanEmail || toCanSecretKey) {
            return true
        }
        return false
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        e.stopPropagation()

        const form = e.currentTarget as HTMLFormElement
        const isValid = form.checkValidity()
        setValidated(true)

        if (isValid) {
            let newEntity: Entity = {id: entity.id, name, inn}
            if (sbpLogin && sbpPassword && sbpElplatPhone && sbpOrgId) {
                newEntity.sbp = {
                    login: sbpLogin,
                    password: sbpPassword,
                    elplatPhone: sbpElplatPhone,
                    orgId: sbpOrgId
                }
            }

            if (toCanEmail && toCanSecretKey) {
                newEntity.toCan = {
                    email: toCanEmail,
                    secret_key: toCanSecretKey
                }
            }

            updateEntity(newEntity)
        }

    }

    const deleteFirmHandle = () => {
       deleteEntity(entity.id)
    }

    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <strong>{name}</strong>
                </Accordion.Header>
                <Accordion.Body>
                    <Card>
                        <Card.Body>
                            <Form noValidate   validated={validated} onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Название организации</Form.Label>
                                            <Form.Control
                                                defaultValue={name}
                                                onChange={e => setName(e.target.value)}/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>ИНН</Form.Label>
                                            <Form.Control
                                                defaultValue={inn}
                                                onChange={e => setInn(e.target.value)}/>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Card className="mb-3">
                                    <Card.Header>СБП</Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <Form.Label>Логин</Form.Label>
                                                <Form.Control
                                                    required={isSbpRequired()}
                                                    defaultValue={sbpLogin}
                                                    onChange={ e => setSbpLogin(e.target.value)}/>
                                            </Col>
                                            <Col>
                                                <Form.Label>Пароль</Form.Label>
                                                <Form.Control
                                                    required={isSbpRequired()}
                                                    defaultValue={sbpPassword}
                                                    onChange={e => setSbpPassword(e.target.value)}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Label>orgId</Form.Label>
                                                <Form.Control
                                                    required={isSbpRequired()}
                                                    type="int"
                                                    defaultValue={sbpOrgId}
                                                    onChange={e => setSbpOrgId(parseInt(e.target.value))}/>
                                            </Col>
                                            <Col>
                                                <Form.Label>elplatPhone</Form.Label>
                                                <Form.Control
                                                    required={isSbpRequired()}
                                                    defaultValue={sbpElplatPhone}
                                                    onChange={e => setSbpElplatPhone(e.target.value)}/>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                <Card className="mb-3">
                                    <Card.Header>ToCan</Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <Form.Label>E-mail</Form.Label>
                                                <Form.Control
                                                    required={isToCanRequired()}
                                                    type="email"
                                                    defaultValue={toCanEmail}
                                                    onChange={e => setToCanEmail(e.target.value)}/>
                                            </Col>
                                            <Col>
                                                <Form.Label>Secret key</Form.Label>
                                                <Form.Control
                                                    required={isToCanRequired()}
                                                    defaultValue={toCanSecretKey}
                                                    onChange={e => setToCanSecretKey(e.target.value)}/>
                                            </Col>

                                        </Row>
                                    </Card.Body>
                                </Card>

                                <Row className="mb-3">
                                    <Col>
                                        <Button type="submit" variant="primary">Обновить</Button>
                                    </Col>

                                    <Col>
                                        <Button variant="danger" onClick={deleteFirmHandle}>Удалить</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
})

export default EntityCard