import React from "react"
import { observer } from "mobx-react-lite"
import { Col, Nav, Row, Tab } from "react-bootstrap"

const Loyalty = observer(() =>{
    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
            <Row>
                <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="1">
                                Промокод
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="2">
                                Бонусы
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="3">
                                Клиенты
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="4">
                                Карты
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="1">
                            Промокод
                        </Tab.Pane>
                        <Tab.Pane eventKey="2">
                            Бонусы
                        </Tab.Pane>
                        <Tab.Pane eventKey="3">
                            Клиенты
                        </Tab.Pane>
                        <Tab.Pane eventKey="4">
                            Карты
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
})

export default Loyalty