import React from "react"
import getSymbolFromCurrency from 'currency-symbol-map'
import {VictoryBar, VictoryChart, VictoryAxis, VictoryContainer, VictoryTheme} from 'victory'

type coinProps = {
    market: any
}

const CoinBox = ({market}: coinProps) => {

    let currentCurrency;
    market?.condition?.devices?.billvalidator.data.version.currency === 'RUR'
        ? currentCurrency = 'RUB' 
        : currentCurrency = market?.condition?.devices?.billvalidator.data.version.currency;

    const currency = getSymbolFromCurrency(currentCurrency)

    let coinSumbox = 0
    const colors = ['#2B3E50', '#4F2B50', '#503D2B', '#2B502B']
    const data: any[] | undefined = []
    let tickValues: any[] | undefined = []
    let tickFormat: any[] | undefined = []

    if(market?.condition?.devices?.coin.data.cashbox_level.length === 0) {
        return <></>
        /*(
            <div className="details_block details_block--sm">
                <h4 className="text-center details__header">Монеты в ящике</h4>
                <p className="text-center mt-5">Нет данных</p>
            </div>
        )*/
    }


    let index = 0
    Object.keys(Object.assign({}, market?.condition?.devices?.coin.data.cashbox_level)).forEach((coin) => {
        let countCoins = market?.condition?.devices?.coin.data.cashbox_level[coin]
        if (countCoins>0) {
            data.push({
                quarter: index + 1,
                count: countCoins,
                fill: colors[index],
                label: `${countCoins} шт.`
            })

            let value = parseInt(coin) / 100
            coinSumbox += value * countCoins
            tickValues?.push(index+1)
            tickFormat?.push(`${value} руб`)
            index++
        }

    })

    if (data.length === 0) {
        return <></>
    }

    return (
        <div className="details_block details_block--sm">
            <h4 className="text-center details__header">Монеты в ящике</h4>
            <VictoryChart
                domainPadding={{ x: 5 }}
                theme={VictoryTheme.material}
                containerComponent={<VictoryContainer className="myChart"/>}
            >
                <VictoryAxis
                    tickValues={tickValues}
                    tickFormat={tickFormat}
                    style={{tickLabels: {fontSize: 20}}}
                />
                <VictoryBar
                    data={data}
                    x="quarter"
                    y="count"
                    barRatio={1.2}
                    style={{
                        data: {
                            fill: ({ datum }) => datum.fill,
                            stroke: ({ datum }) => datum.fill,
                            fillOpacity: 0.7,
                            strokeWidth: 3
                        },
                        labels: {
                            fontSize: 20
                        }
                    }}
                />
            </VictoryChart>

            <p className="text-center text--bold text--blue">Итого: {coinSumbox} {currency}</p>
        </div>
    )
}

export default CoinBox
