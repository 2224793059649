import React, { useEffect } from "react";
import {observer} from "mobx-react-lite";
import {useRootStore} from "../../RootStateContext";

const Collection = observer(() => {
    const {marketsStore} = useRootStore()
    const {loadCollections, currentMarketCollections} = marketsStore

    useEffect(() => {
        loadCollections()
    }, [])

    return (
        <div className="events">
            <table className='table'>
                <thead>
                    <tr>
                        <td>Дата</td>
                        <td>Монеты</td>
                        <td>Купюры</td>
                        <td>Тип</td>
                    </tr>
                </thead>
                <tbody>
                    {currentMarketCollections?.map( e => (
                        <tr key={e.id}>
                            <td>{e.date.substring(0, e.date.lastIndexOf(':'))}</td>
                            <td>{e.coins}</td>
                            <td>{e.notes}</td>
                            <td>
                                {e.type === 'add' && 'Добавление'}
                                {e.type === 'pull' && 'Снятие'}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
})

export default Collection;