import React, {useEffect, useState } from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../RootStateContext"
import {PurchaseLine, Purchase, PurchasePay} from "../../stores/MarketsStore";


const Purchases = observer(() => {
    const {marketsStore} = useRootStore()
    const {loadPurchases, currentMarketPurchases} = marketsStore

    useEffect(() => {
        loadPurchases()
    }, [])

    const [currentPurchase, setCurrentPurchase] = useState<Purchase>()

    const productDict = {
        'water': 'Вода',
        'bottle': 'Тара',
        'cork': 'Пробка',
        'prepay': 'Предоплата'
    }

    const payDict = {
        'cash': 'Наличные',
        'emoney': 'Банк',
        'qr': 'QR',
        'prepay': 'Счёт клиента'
    }

    return (
        <div className="events">
            {
                currentPurchase &&
                <div>
                    <div className="current_purchase_grid">
                        <div>ID</div>
                        <div>{currentPurchase.internal_id}</div>

                        <div>Дата</div>
                        <div>{currentPurchase.date}</div>

                        <div>Позиции</div>
                        <div className="current_purchase_lines">
                            <div>Товар</div>
                            <div>Количество</div>
                            <div>Стоимость</div>
                            {
                                currentPurchase.lines.map((line, i) => {
                                    return (
                                        <>
                                            <div>{productDict[line.product as keyof typeof productDict]}</div>
                                            <div>{line.qt}</div>
                                            <div  className="current_purchase_money">{line.cost}</div>
                                        </>
                                    )
                                })
                            }
                        </div>

                        <div>Сумма</div>
                        <div className="current_purchase_money">{currentPurchase.sum}</div>

                        <div>Оплата</div>
                        <div className="current_purchase_pays">
                            <div>Тип</div>
                            <div>Взято</div>
                            <div>Выдано</div>
                            <div>Заблокировано</div>
                            <div>Забыто</div>
                            <div>Транзакции</div>
                            {
                                currentPurchase.pays.map((pay, i) => {
                                    return (
                                        <>
                                            <div>{payDict[pay.type as keyof typeof payDict]}</div>
                                            <div>{pay.taken}</div>
                                            <div>{pay.given}</div>
                                            <div>{pay.locked}</div>
                                            <div>{pay.forgotten}</div>
                                            <div>-</div>
                                        </>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
            }

            <table className='table table--hover'>
                <thead>
                <tr>
                    <td>Дата</td>
                    <td>Товары</td>
                    <td>Оплата</td>
                    <td>Пользователь</td>
                    <td>Сумма</td>
                </tr>
                </thead>
                <tbody>
                {currentMarketPurchases?.map((purchase, i) => {
                    return (
                        <tr
                            key={i}
                            onClick={() => setCurrentPurchase(purchase)}
                            className={purchase?.id === currentPurchase?.id ? 'active' : ''}
                        >
                            <td>{purchase.date}</td>
                            <td>{
                                purchase.lines?.map( (line: PurchaseLine, i: number) => {
                                    return (
                                        <>
                                            {productDict[line.product as keyof typeof productDict]} {line.qt}
                                            {(i+1)<purchase.lines.length && <> / </>}
                                        </>
                                    )
                                })
                            }</td>
                            <td>{
                                purchase.pays?.map( (pay: PurchasePay, i: number) => {
                                    return (
                                        <>
                                            {payDict[pay.type as keyof typeof payDict]}
                                            {(i+1)<purchase.pays.length && <> / </>}
                                        </>
                                    )
                                })
                            }</td>
                            <td>{purchase?.user?.phone}</td>
                            <td>{purchase.sum} руб.</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )


})

export default Purchases