import api from './api'

export const getBrand = async () => {
    const response = await api.get(
        'brand', 
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                // "Referer": "http://customer.it-vend.ru"
            }
        }
    )
    return response.data
}

export const updateBrand = async (name: string, logotype: File) => {
    let brandData = new FormData()
    brandData.append('name', name)
    brandData.append('logotype', logotype)

    const response = await api.post(
        'brand', 
        brandData,
        {
            headers: { 
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                'Content-Type': 'multipart/form-data'
            }
        }
    )
    return response.data
}
