import {observable, action, makeAutoObservable, runInAction, set} from "mobx"
import {
    getMarkets,
    sendComand,
    getSells,
    getPurchases,
    getCollections,
    getQr,
    putEvents,
    getEvents,
    putAllEvents,
    getMarketGroups,
    createMarketGroup,
    updateMarketGroup,
    deleteMarketGroup,
    updateMarket,
    getMesages, deactivateMessages
} from "../api/markets"
import { commandActive, commandMessage, CurrentSell, MarketEvents, MarketMoney, MarketProducts, MarketGroup } from "./interfaces/marketInterfaces"

interface Entity {
    id: number,
    name: string
}

export interface Market {
    id: number,
    serial: number,
    group_id: number,
    address: string,
    number: string,
    products?: MarketProducts,
    events?: MarketEvents[],
    money?: MarketMoney,
    condition: any,
    messages_active_count: number,
    stats?: any,
    version?: string,
    entity?: Entity,
    statuses?: any,
    ping?: Date,
    ping_status?: string,
    selling_timeout?: boolean,
    last_selling_date?: string,
    device_fail: boolean
}

export interface PurchaseLine {
    cost: number,
    product: string,
    qt: number
}

export interface PurchasePay {
    type: string,
    taken: number,
    given: number,
    locked: number,
    forgotten: number
}

interface PurchaseUser {
    id: number,
    phone: string
}
export interface Purchase {
    id: number,
    internal_id: string,
    date: string,
    locked: number,
    forgotten: number,
    sum: number,
    lines: PurchaseLine[]
    pays: PurchasePay[],
    user?: PurchaseUser
}

const device_fail_codes = [
    'bill-0x01',
    'bill-0x02',
    'bill-0x05',
    'bill-0x08',
    'bill-32',
    'bill-34',
    'bill-36',
    'bill-37',
    'bill-40',
    'coin-0x01',
    'coin-0x03',
    'coin-0x04',
    'coin-0x06',
    'coin-0x07',
    'coin-0x0C',
    'coin-0x0510',
    'coin-0x1000',
    'coin-0x1001',
    'coin-0x1002',
    'coin-0x1003',
    'coin-0x1100',
    'coin-0x1110',
    'coin-0x1111',
    'coin-0x1130',
    'coin-0x1141',
    'coin-0x1150',
    'coin-0x1151',
    'coin-0x1152',
    'coin-0x1153',
    'coin-0x1154',
    'coin-0x1200',
    'coin-0x1230',
    'coin-0x1231',
    'coin-0x1240',
    'coin-0x1250',
    'coin-0x1300',
    'coin-0x1310',
    'coin-0x1400',
    'coin-0x1500',
    'coin-0x1502',
    'coin-0x1503',
    'coin-0x1504',
    'cashless----',
    'loyalty-E11',
    'dispenser-25',
    'kiosk-10',
    'kiosk-12'
]
export class MarketsStore {

    constructor() {
        makeAutoObservable(this);
    }

    @observable markets: Market[] = [];
    @observable currentMarket?: Market;

    @observable groups: MarketGroup[] = [];
    @observable currentGroupId?: number;

    @observable pageReloadTime?: number;
    @observable currentMarketQr?: any;
    @observable currentMarketEvents?: any[];
    @observable currentMarketMessages?: any[];
    @observable currentMarketActiveEvents?: number[] = [];
    @observable currentMarketSells?: any[];
    @observable currentSell?: CurrentSell;

    @observable currentMarketPurchases?: Purchase[];
    @observable currentPurchase?: Purchase;

    @observable currentMarketCollections?: any[];


    @observable marketsList: Market[] = [];
    @observable commandsActive: commandActive[] = []
    @observable commandsMessage: commandMessage[] = []

    @action setCurrentGroupId = (groupId: number) => {
        this.currentGroupId = groupId;
        this.marketsList = this.markets.filter((market: Market) => {return market.group_id === groupId})
    }

    @action resetFilter = () => {
        this.currentGroupId  =  0
        this.marketsList = this.markets
    }

    @action loadGroups = (reload: boolean = false) => {
        if (this.groups.length > 0 && !reload) {
            return
        }

        getMarketGroups()
            .then( data => {
                runInAction(()=>{
                    this.groups = data;
                })

            })
            .catch( e => {
                console.log('loadError', e)
            })
    }

    @action createGroup = (name: string) => {
        createMarketGroup(name)
            .then( data => {
                this.groups.push(data)
            })
            .catch( e => {
                console.log('Create error', e)
            })

    }

    @action updateGroup = (id: number, name: string) => {
        updateMarketGroup(id, name)
            .then( data => {
                this.groups = this.groups.map( g => {
                    if (g.id === data.id) {
                        g.name = data.name
                    }
                    return g;
                })
            })
            .catch( e => {
                console.log('Update error', e)
            })
    }

    @action deleteGroup = (id: number) => {
        deleteMarketGroup(id)
            .then( data => {
                this.groups = this.groups.filter( g => g.id !== id)
            })
            .catch( e => {
                console.log('Delete error', e)
            })
    }

    @action loadMarkets = () => {
        getMarkets()
            .then( data => {
                runInAction(() => {
                    this.markets = data.map( (m: any) => {
                        if (m.condition) {
                            m.condition.bill_total = 0;
                            m.condition.coin.box_total = 0;
                            m.condition.coin.tubes_total = 0;
                            if (m.condition.bill) {
                                for (const key in m.condition.bill) {
                                    m.condition.bill_total += +key * m.condition.bill[key]
                                }
                            }

                            if (m.condition.coin) {
                                for (const key in m.condition.coin.box) {
                                    m.condition.coin.box_total += +key * m.condition.coin.box[key]
                                }

                                for (const key in m.condition.coin.tubes) {
                                    m.condition.coin.tubes_total += +key * m.condition.coin.tubes[key]
                                }
                            }

                            m.condition.coin.total = m.condition.coin.box_total + m.condition.coin.tubes_total

                        }

                        if (m.ping) {
                            let afterPingSeconds = (new Date()).getTime() / 1000 - m.ping;
                            if (afterPingSeconds < 900) {
                                m.ping_status = 'ok';
                            } else if (afterPingSeconds < 1800) {
                                m.ping_status = '15m'
                            } else if (afterPingSeconds < 3600) {
                                m.ping_status = '30m'
                            } else if (afterPingSeconds < 7200) {
                                m.ping_status = '1h'
                            } else {
                                m.ping_status = '2h'
                            }
                            m.ping = new Date(m.ping * 1000)

                        }
                        m.selling_timeout = 'na'
                        m.last_selling_date = 'неизвестно'
                        if (m.stats && m.stats.last_selling_date) {
                            let now = new Date()
                            m.last_selling_date = new Date(m.stats.last_selling_date * 1000).toLocaleString()
                            m.selling_timeout = (now.setHours(now.getHours() - 2) >  m.stats.last_selling_date * 1000) ? '2h' : 'ok'
                        }

                        m.device_fail = false
                        if (m.statuses) {
                            for (const device in m.statuses) {
                                if (device_fail_codes.indexOf(device + '-' + m.statuses[device]) !== -1) {
                                    m.device_fail = true;
                                }
                            }
                        }
                        return m
                    })
                    this.marketsList = this.markets
                })

            })
            .catch( e => {
                console.log('loadError', e)
            })
    }

    @action updateMarket = (id: number, settings: any) => {
        updateMarket(id, settings)
            .then(data => {
                console.log(data)
            })
            .catch( e => {
                console.error('Update market error', e)
            })
    }

    @action load = () => {
        this.loadGroups()
        this.loadMarkets()
    }

    @action loadSells = () => {
        if (this.currentMarket !== undefined) {
            this.currentMarketSells = []
            getSells(this.currentMarket.id)
                .then( data => {                                                         
                    this.currentMarketSells = data 
                    this.currentSell = this.currentMarketSells![0]               
                })
        }
    }

    @action loadPurchases = () => {
        if (this.currentMarket !== undefined) {
            this.currentMarketPurchases = []
            getPurchases(this.currentMarket.id)
                .then( data => {
                    this.currentMarketPurchases = data
                    this.currentPurchase = this.currentMarketPurchases![0]
                })
        }
    }

    @action setCurrentPurchase = (purchase: any) => {
        this.currentPurchase = purchase
    }


    @action setCurrentSell = (sell: CurrentSell) => {
        this.currentSell = sell
    }

    @action loadCollections = () => {
        if (this.currentMarket !== undefined) {
            this.currentMarketCollections = []
            getCollections(this.currentMarket.id)
                .then( data => {                    
                    this.currentMarketCollections = data      
                    // console.log('loadCollections', data);
                })
        }
    }

    @action loadQr = () => {
        if (this.currentMarket !== undefined) {
            this.currentMarketQr = []
            getQr(this.currentMarket.id)
                .then( data => {                  
                    this.currentMarketQr = data;                    
                })
        }
    } 
    
    @action loadEvents = () => {
        if (this.currentMarket !== undefined) {
            this.currentMarketQr = []
            getEvents(this.currentMarket.id)
                .then( data => {         
                    this.currentMarketEvents = data       
                    this.currentMarketActiveEvents = data.filter((event: { status: string; }) => event.status === "active").map((event: { id: any; }) => event.id)
                    console.log('loadEvents', data);
                    console.log('loadactiveEvents', this.currentMarketActiveEvents);
                               
                })
        }
    }

    @action loadMessages = () => {
        if (this.currentMarket !== undefined) {
            getMesages(this.currentMarket.id)
                .then( data => {
                    this.currentMarketMessages = data;
                })
        }
    }

    @action deactivateMessages = (messageId?: number) => {
        if (this.currentMarket !== undefined) {
            deactivateMessages(this.currentMarket.id, messageId)
                .then((data) => {
                    this.loadMessages()
                })

        }
    }
    @action setCurrentMarket = (market: Market) => {        
        if (this.currentMarket === undefined || this.currentMarket.id !== market.id) {
            this.currentMarket = market            
        } else {
            this.currentMarket = undefined
        }
        this.loadSells()
        this.loadCollections()
        this.loadQr()
        this.loadPurchases()
    }

    @action putEvents = (eventId: number) => {        
        if(this.currentMarket !== undefined) {
            putEvents(this.currentMarket.id, eventId)
                .then( data => {
                    this.loadEvents()
                })
        }
    }

    @action putAllEvents = () => {        
        if(this.currentMarket !== undefined) {
            putAllEvents(this.currentMarket.id, this.currentMarketActiveEvents!)
                .then( () => {
                    this.loadEvents()
                })
        }
    }



    @action clearCurrentMarket = () => {        
        this.currentMarket = undefined
    }

    @action sendCommand = (command: string) => {
        if (this.currentMarket !== undefined) {
            let market_id = this.currentMarket?.id
            if (this.commandsActive.find(command => market_id === command.market)) {
                //Дождитесь окончания выполнения команды на данном киоске
                return;
            }
            this.commandsActive.push({key: command, market: market_id})
            let startDate = Date.now()
            sendComand(this.currentMarket.id, command)
                .then(data => {

                })
                .catch( e => {
                    this.commandsMessage.push({command: command, market_id: market_id, message: e.response.data.message})
                })
                .finally(()=> {
                    const animationTimeout = 500
                    let clearLock = () => {
                        this.commandsActive = this.commandsActive.filter( ac => !(ac.key === command && this.currentMarket?.id === ac.market))
                    }
                    let passedTime = Date.now() - startDate
                    if (passedTime < animationTimeout) {
                        setTimeout(clearLock, (animationTimeout - passedTime))
                    } else {
                        clearLock()
                    }
                })
        }
    }

    @action loadTimeout = () => {
        let that = this
        let time = parseInt(localStorage.getItem('timer') || '5') * 60 * 1000
        setTimeout(function run() {
            let time = parseInt(localStorage.getItem('timer') || '5') * 60 * 1000

            console.log('loadTimeout');
            
            that.loadMarkets()
            setTimeout(run, time)




        }, time)
    }

    mapKioskStatus = (status: string): string => {
        switch (status) {
            case '1':
                return 'Ожидание';
            case '2':
                return 'Покупка';
            case '3':
                return 'Начало сеанса';
            case '4':
                return 'Выбор товара';
            case '5':
                return 'Выбор оплаты';
            case '6':
                return 'Выдача товара';
            case '7':
                return 'Завершение расчетов';
            case '8':
                return 'Завершение сеанса';
            case '9':
                return 'Сервис';
            case '10':
                return 'Заблокирован';
            case '11':
                return 'Перезагрузка';
            case '12':
                return 'Нет воды';
        }

        return '';
    }
}