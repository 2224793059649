import React, {useState} from "react"
import {Col, Row, Card, Form, Button} from "react-bootstrap"
import {useRootStore} from "../../../../RootStateContext";

const MarketGroupCard = (props: any) => {

    const {marketsStore} = useRootStore()
    const {updateGroup, deleteGroup} = marketsStore

    const [name, setName ] = useState(props.name)
    const handleUpdateForm = (e: React.FormEvent) => {
        e.preventDefault()
        e.stopPropagation()
        updateGroup(props.id, name)
        return false
    }

    const deleteGroupHandler = (id: number) => {
        deleteGroup(id)
    }

    return <Card>
        <Card.Body>
            <Form onSubmit={handleUpdateForm}>
                <Row>
                    <Col>
                        <Form.Control type="hidden" defaultValue={props.id}></Form.Control>
                        <Form.Control type="text" defaultValue={name}  onChange={e => setName(e.target.value)}></Form.Control>
                    </Col>
                    <Col md="auto">
                        <Button type="submit" variant="primary">Обновить</Button>
                    </Col>
                    <Col md="auto">
                        <Button variant="danger" onClick={ (e) => deleteGroupHandler(props.id)}>Удалить</Button>
                    </Col>
                </Row>
            </Form>
        </Card.Body>
    </Card>

}

export default MarketGroupCard