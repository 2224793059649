import api from './api'

export const getUsers = async () => {
    const response = await api.get(
        'user', 
        {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
    )
    return response.data
}

export const createUser = async (email: string, password: string, role: string, name: string) => {
    console.log('createUser');
    
    const response = await api.post(
        'user', 
        {email, password, role, name},
        {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
    )
    return response.data
}

export const createUserPassword = async (userId: number, password: string) => {
    const response = await api.put(
        `user/${userId}/password`, 
        {password},
        {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
    )
    return response.data
}

export const createUserStatus = async (userId: number, status: string) => {
    const response = await api.put(
        `user/${userId}/status`, 
        {status},
        {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
    )
    return response.data
}

export const createUserRole = async (userId: number, role: string) => {
    const response = await api.put(
        `user/${userId}/role`, 
        {role},
        {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
    )
    return response.data
}