import React, {useState} from "react"
import {useRootStore} from "../../../../RootStateContext"
import {Col, Row, Card, Form, Button} from "react-bootstrap"

import {observer} from "mobx-react-lite";


type personalizationProps = {
    personalization: {
        id: number,
        name: string,
        logotype: string
    }
}

const PersonalizationCard = ({personalization}: personalizationProps) => {
    const {configurationStore} = useRootStore()
    const {updatePersonalization, deletePersonalization} = configurationStore

    const [name, setName] = useState(personalization.name)
    const [file, setFile] = useState()
    const [fileName, setFileName] = useState('')
    const [extError, setExtError] = useState('')
    const [sizeError, setSizeError] = useState('')

    const onChangeFile = (e:any) => {
        setExtError('')
        setSizeError('')

        if (e.target.files[0].type !== 'image/png') {
            setExtError('Изображение должно именть формат PNG.')
        }

        if (e.target.files[0].size / 1024 >= 500) {
            setSizeError('Размер файла не должен превышать 500кб.')
        }

        if(extError || sizeError) {
            return
        } else {
            let files = e.target.files;
            setFile(files[0])
            setFileName(files[0].name)
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        updatePersonalization(personalization.id, name, file!)
    };

    return (
        <Card>

            <Card.Body>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md="auto">
                            <img src={'data:image/png;base64,' + personalization.logotype} width={200}/>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Название</Form.Label>
                                <Form.Control
                                    value={name}
                                    onChange={ (e) => setName(e.target.value)}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Логотип</Form.Label>
                                <Form.Control
                                    type="file"
                                    size="sm"
                                    onChange={(e) => onChangeFile(e)}/>
                                <Form.Text className="text-muted">В формате PNG, не более 500кб.</Form.Text>
                            </Form.Group>
                            <Row>
                                <Col md="auto"><Button variant="primary" type="submit">Изменить</Button></Col>
                                <Col><Button variant="danger" onClick={(e) => deletePersonalization(personalization.id)}>Удалить</Button></Col>
                            </Row>
                        </Col>
                    </Row>


                </Form>
            </Card.Body>
        </Card>
    )
}

export default PersonalizationCard