import React, {useEffect} from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../RootStateContext"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"

// import {IconContext} from "react-icons"
// import {VscDebugConsole, VscInfo, VscWarning, VscError} from 'react-icons/vsc'
// import {MdOutlineSmsFailed} from 'react-icons/md'
// import {GrStatusCritical} from 'react-icons/gr'

const Messages = observer(() => {
    const {marketsStore} = useRootStore()
    const {currentMarketMessages, loadMessages, deactivateMessages} = marketsStore

    useEffect(() => {
        loadMessages()
    }, [])

    return (
        <div className="events">
            <Button onClick={() => deactivateMessages()} className='button--blue mb-3'>Прочитать все</Button>
            <div className="events">
                <table className='table table--events'>
                    <thead>
                        <tr>
                            <td>Дата</td>
                            <td>Устройство</td>
                            <td>Протокол</td>
                            <td>Код</td>
                            <td>Сообщение</td>
                        </tr>
                    </thead>
                    <tbody>
                        {currentMarketMessages?.map( m => {
                            return(
                                <tr key={m.id}>
                                    <td>{m.date}</td>
                                    <td>{m.device}</td>
                                    <td>{m.protocol}</td>
                                    <td>{m.code}</td>
                                    <td>
                                        {m.message_base}
                                        {m.message &&
                                            <> ({m.message})</>
                                        }

                                        {m.active == true &&
                                            <OverlayTrigger
                                                key={'top'}
                                                placement={'top'}
                                                overlay={
                                                    <Tooltip id={`tooltip-${'top'}`}>
                                                        Пометить как прочитанное
                                                    </Tooltip>
                                                }
                                            >
                                                <Button variant="primary" onClick={() => deactivateMessages(m.id)}>&times;</Button>
                                            </OverlayTrigger>
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
})

export default Messages