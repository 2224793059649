import {observable, action, makeAutoObservable, runInAction} from "mobx"
import {
    createPersonalisation,
    deletePersonalization,
    loadPersonalizations,
    updatePersonalization
} from '../api/personalization';

import {loadCities} from "../api/city";

type personalization = {
    id: number,
    name: string,
    logotype: string
}

type city = {
    id: number,
    name: string
}

export class ConfigurationStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable personalizations: personalization[] = []

    @observable cities: city[] = []

    @action loadPersonalizations = () => {
        loadPersonalizations()
            .then( data => {
                runInAction(() => {
                    this.personalizations = data
                })
            })
    }

    @action createPersonalisation = (name: string, logotype: File) => {
        createPersonalisation(name, logotype)
            .then( data => {
                runInAction(()=>{
                    this.personalizations.push(data)
                })
            })
            .catch( e => {
                console.log('Create peronalization fail', e)
            })
    }

    @action updatePersonalization = (id: number, name: string, logotype?: File) => {
        updatePersonalization(id, name, logotype)
            .then( data => {
                runInAction(()=>{
                    this.personalizations = this.personalizations.map( p => {
                        if (p.id === data.id) {
                            p.name = data.name
                            p.logotype = data.logotype
                        }
                        return p
                    })
                })
            })
            .catch( e => {
                console.log('Update personalization fail', e)
            })
    }

    @action deletePersonalization = (id: number) => {
        console.log('delete')
        deletePersonalization(id)
            .then( data => {
                runInAction(()=>{
                    this.personalizations = this.personalizations.filter((p) => p.id !== id)
                })
            })
            .catch(e => {
                console.log('Delete personalization fail', e)
            })
    }

    @action loadCities = () => {
        loadCities()
            .then( data => {
                runInAction(()=>{
                    this.cities = data
                })
            })
    }
}
