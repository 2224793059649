import React, {useEffect, useState} from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../RootStateContext"
import Loader from "../Loader"
import ReactTooltip from 'react-tooltip'
import {BsListUl, BsGrid3X3GapFill} from 'react-icons/bs'
import {BiStopwatch} from 'react-icons/bi'

const List = observer(() => {
    const {marketsStore, authStore} = useRootStore()
    const {marketsList, load, setCurrentMarket, currentMarket, loadSells, loadCollections} = marketsStore

    const [displayMode, setDisplayMode] = useState('cardList')

    useEffect(() => {
        load()
        loadSells()
        loadCollections()
    }, [])


    if (marketsList.length === 0) {
        return <Loader/>
    }

    return (
        <>
            {/*
            <div className="gridChanger">
                <button onClick={() => {setDisplayMode('cardGrid')}} className={displayMode === 'cardGrid' ? 'active' : ''}>
                    <BsGrid3X3GapFill size={25}/>  
                </button>

                <button onClick={() => {setDisplayMode('cardList')}} className={displayMode === 'cardList' ? 'active' : ''}>
                    <BsListUl size={25}/>  
                </button>
            </div>
            */}
            <div className={"kioskList kioskList__header"}>
                <div>№</div>
                <div>Адрес</div>
                <div>Статус</div>
                <div>Связь</div>
                <div>Вода</div>
                <div>Тара</div>
                <div>Пробки</div>
                <div>Карты</div>
                <div>Наличные</div>
                <div>Продажи</div>
                <div>Диагностика</div>
                <div>Сообщения</div>
            </div>
                {marketsList.map(market => {
                   return (
                       <div className={"kioskList kioskList__row"} key={market.id} onClick={() => setCurrentMarket(market)}>
                           <div>{market.number}</div>
                           <div>{market.address}</div>
                           <div>
                               <div
                                   data-for="status"
                                   data-place="top"
                                   data-effect="solid"
                                   data-tip={marketsStore.mapKioskStatus(market?.statuses?.kiosk)}
                                   className={"kiosk_status kiosk_status__" + market?.statuses?.kiosk}>
                               </div>
                               <ReactTooltip id="status" className='tooltip'/>
                           </div>
                           <div>
                               <div
                                   data-for="time"
                                   data-place="top"
                                   data-effect="solid"
                                   data-tip={"Последный выход на связь " + market?.ping?.toLocaleString('ru')}
                                   className={"kiosk_ping kiosk_ping__" + market?.ping_status}
                               ></div>
                               <ReactTooltip id="time" className='tooltip'/>
                           </div>
                           <div>{market?.condition?.w}</div>
                           <div>{market?.condition?.b_k + market?.condition?.b_t}</div>
                           <div>{market?.condition?.cork_k + market?.condition?.cork_t}</div>
                           <div>{market?.condition?.cards}</div>
                           <div>{market?.condition ? (market.condition.coin.total + market.condition.bill_total) : ''}</div>
                           <div>
                               <div
                                   data-for="last_purchase_tip"
                                   data-place="top"
                                   data-effect="solid"
                                   data-tip={"Последняя продажа " + market?.last_selling_date}
                                   className={"kiosk_purchase_timeout kiosk_purchase_timeout__" + market.selling_timeout}>

                               </div>
                               <ReactTooltip id="last_purchase_tip" className='tooltip'/>
                           </div>
                           <div>
                                <div className={"kiosk_device_status kiosk_device_status__" + ((market.device_fail)?'fail':'ok')}></div>
                           </div>
                           <div>{market.messages_active_count}</div>
                       </div>
                   )
                })}

            {/*
            <div className={"listWrapper__" + displayMode}>
                {marketsList.map(market => {
                    return (
                        <div key={market.id} onClick={() => setCurrentMarket(market)} className={displayMode}>
                            <div className={displayMode + '__head'}>
                                <div className={displayMode + "__head__left"}>
                                    <div className={displayMode + "__head__number"}>{market.number}</div>
                                    <div className={displayMode + "__head__address"}>{market.address}</div>
                                </div>
                                <div className={displayMode + "__head__right"}>
                                    <div
                                        data-for="status"
                                        data-place="top"
                                        data-effect="solid"
                                        data-tip={marketsStore.mapKioskStatus(market?.statuses?.kiosk)}
                                        className={"kiosk_status kiosk_status__" + market?.statuses?.kiosk}>
                                    </div>
                                    <div
                                        data-for="time"
                                        data-place="top" 
                                        data-effect="solid" 
                                        data-tip={"Последный выход на связь " + market?.ping?.toLocaleString('ru')}
                                        className={"kiosk_ping kiosk_ping__" + market?.ping_status}
                                    ></div>
                                    <ReactTooltip id="status" className='tooltip'/>
                                    <ReactTooltip id="time" className='tooltip'/>
                                </div>
                            </div>

                            <div className={displayMode + "__content"}>
                                <div className={displayMode + "__content__first"} data-for="events" data-place="top" data-effect="solid" data-tip="События">
                                    <div className={`block ${market.events_active_count ? "danger" : ""}`}>{market.events_active_count}</div>
                                    <ReactTooltip id="events" className='tooltip'/>
                                </div>
                                <div className={displayMode + "__content__second"}>
                                    <div className="productWater block" data-for="water" data-place="top" data-effect="solid" data-tip="Вода">
                                        {Math.ceil(market.condition?.calculated?.water_in_tank) || '0'}
                                        <ReactTooltip id="water" className='tooltip'/>
                                    </div>
                                    <div className="productBottle block" data-for="bottle" data-place="top" data-effect="solid" data-tip="Тара">
                                        {market.condition?.calculated?.bottles_in_kiosk + market.condition?.calculated?.bottles_in_tray || '0'}
                                        <ReactTooltip id="bottle" className='tooltip'/>
                                    </div>
                                    {/* <div className="productCard" data-for="card" data-place="top" data-effect="solid" data-tip="Карта">
                                        {market.products?.card}
                                        <ReactTooltip id="card"/>
                                    </div> * /}
                                    <div className="productCork block" data-for="cork" data-place="top" data-effect="solid" data-tip="Пробка">
                                    {market.condition?.calculated?.corks_in_kiosk + market.condition?.calculated?.corks_in_tray || '0'}
                                        <ReactTooltip id="cork" className='tooltip'/>
                                    </div>
                                </div>
                                <div className={displayMode + "__content__last"}>
                                    <div className="moneyCash block" data-for="cash" data-place="top" data-effect="solid" data-tip="Наличные">
                                        {market.condition? (market.condition.coin.total + market.condition.bill_total) : 0}
                                        <ReactTooltip id="cash" className='tooltip'/>
                                    </div>
                                    <div className="moneyBank block" data-for="bank" data-place="top" data-effect="solid" data-tip="Безналичные">
                                        {'0'}
                                        <ReactTooltip id="bank" className='tooltip'/>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    )
                })}
            </div>
            */}
        </>
    )
})

export default List