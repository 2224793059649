import React from "react"
import { observer } from "mobx-react-lite"
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap"
import Firm from "./managmentComponents/Firm"
import Loyalty from "./managmentComponents/loyalty"
import Partners from "./managmentComponents/partners"
import Configurations from "./managmentComponents/configurations"
import TaskManager from "./managmentComponents/taskManager"
import Logs from "./managmentComponents/logs"

const ManagmentPage = observer(() =>{
    return (
        <Container fluid className="managment">
            <Row>
                <Col>
                    <Tabs defaultActiveKey="1" id="uncontrolled-tab-example" className="mb-3 managment__nav">
                        <Tab eventKey="1" title="Бренд">
                            <Firm/>
                        </Tab>
                        <Tab eventKey="2" title="Лояльность">
                            <Loyalty/>
                        </Tab>
                        <Tab eventKey="3" title="Партнеры">
                            <Partners/>
                        </Tab>
                        <Tab eventKey="4" title="Конфигурации">
                            <Configurations/>
                        </Tab>
                        <Tab eventKey="5" title={<span>Планировщик<br/>задач</span>}>
                            <TaskManager/>
                        </Tab>
                        <Tab eventKey="6" title="Журналы">
                            <Logs/>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    )
})

export default ManagmentPage