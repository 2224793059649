import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Container, Nav, Navbar, Dropdown} from 'react-bootstrap'
import {useRootStore} from "../RootStateContext"
import {observer} from "mobx-react-lite"
import { BiTimer } from "react-icons/bi"
import { BsCheck } from "react-icons/bs"

const timers = ['1', '5']

const Menu = observer(() => {
    const {authStore, marketsStore} = useRootStore()
    const [timerState, setTimerState] = useState('5')
    const {loadTimeout} = marketsStore

    useEffect(() => {
        loadTimeout()

        if(localStorage.getItem('timer') === null) {
            localStorage.setItem('timer', '5')
        }
        
        setTimerState(localStorage.getItem('timer') || '5')
    }, [])

    const switchTimer = (time: string) => {
        localStorage.setItem('timer', time)
        setTimerState(time)
    }

    return (
        <Navbar bg="light" expand="md" collapseOnSelect>
            <Container fluid>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link eventKey="1" as={Link} to="/">Мониторинг</Nav.Link>
                        <Nav.Link eventKey="2" as={Link} to="/reports">Отчеты</Nav.Link>
                        <Nav.Link eventKey="3" as={Link} to="/managment">Управление</Nav.Link>
                        <Nav.Link eventKey="4" as={Link} to="/support">Техподдержка</Nav.Link>                      
                    </Nav>
                    <Navbar.Text>
                        {authStore.user?.email || authStore.email} (<span onClick={ e => authStore.logout()}>выйти</span>)
                    </Navbar.Text>                    
                </Navbar.Collapse>
                <Navbar.Text>
                    <Dropdown 
                        drop="start"
                        className='timer'
                    >
                        <Dropdown.Toggle 
                            id="dropdown-basic"
                            variant="link"
                            className='timer__toggle'
                        >
                            
                            <BiTimer size={25}/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                            className='timer__menu'
                        >
                            {timers.map(time => {
                                return(
                                    <Dropdown.Item
                                        key={time}
                                        active={time === timerState}
                                        onClick={() => switchTimer(time)}
                                        className='timer__item'
                                    >
                                        {time === timerState && <BsCheck/>}   
                                        {time} мин
                                    </Dropdown.Item>
                                )
                            })}
                            {/* <Dropdown.Item active={true}>1 мин</Dropdown.Item>
                            <Dropdown.Item>5 мин</Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </Navbar.Text>
            </Container>
        </Navbar>
    )
})

export default Menu