import React, {useEffect, useState} from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../../../RootStateContext"
import {Col, Row, Card, Form, Button} from "react-bootstrap"
import EntityCard from "./entityCard"

const Entitys = observer(() =>{
    const {firmsStore} = useRootStore()
    const {entitys, loadEntitys, createEntity} = firmsStore

    const [name, setName] = useState('')
    const [inn, setInn] = useState('')

    useEffect(() => {
        loadEntitys()
    }, [])

    const handleSubmit = (event: any) => {
        event.preventDefault()
        event.stopPropagation()

        createEntity(name, inn)
    }

    return (
        <>
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Добавление Организации</Card.Title>
                    <Form onSubmit={handleSubmit} autoComplete="off">
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label htmlFor="newFirmName">Название</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="newFirmName"
                                    size="sm"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label htmlFor="newFirmINN">ИНН</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="newFirmINN"
                                    size="sm"
                                    value={inn}
                                    onChange={(e) => setInn(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                        
                        <Row>
                            <Col sm={2}>
                                <Button variant="primary" type="submit">
                                    Добавить
                                </Button>
                            </Col>
                            <Col>
                            
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            
            {entitys?.map(e => {
                return (
                    <EntityCard entity={e} key={e.id} />
                )
            })}
        </>
    )
})

export default Entitys