import React from "react"
import Toolbar from "./Toolbar"
import List from "./List"
import Details from "./Details"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../RootStateContext"
import Events from "./Events"
import Messages from "./Messages"
import Sells from "./Sells"
import Collection from "./Collections"
import {Offcanvas, Tab, Tabs} from "react-bootstrap"
import Qr from "./details/Qr"
import Purchases from "./Purchases";

const Page = observer(() => {
    const {marketsStore} = useRootStore()
    const {currentMarket, clearCurrentMarket} = marketsStore
    const handleClose = () => clearCurrentMarket()

    return (
        <div className='page'>
            <div className="page__left">
                <Toolbar/>
                <List/>
            </div>
            <Offcanvas show={currentMarket !== undefined} placement='end' onHide={handleClose} className='sidebar'>
                <Offcanvas.Header closeButton>
                {/* <Offcanvas.Title>
                    <p className="text text--blue text--nomb">{currentMarket?.number} {'\u00A0'} {currentMarket?.address}</p>
                </Offcanvas.Title> */}

                </Offcanvas.Header>
                <Offcanvas.Body>
                <Tabs defaultActiveKey="1" id="uncontrolled-tab-example" className="mb-3 sidebar__nav">                     
                    <Tab eventKey="1" title="Детализация">
                        <Details/>
                    </Tab>
                    <Tab eventKey="2" title="Доступ">
                        <Qr/>
                    </Tab>
                    <Tab eventKey="3" title="Сообщения">
                        <Messages/>
                    </Tab>
                    <Tab eventKey="4" title="Продажи">
                        <Purchases/>
                    </Tab>
                    <Tab eventKey="5" title="Инкассации">
                        <Collection/>
                    </Tab>
                </Tabs>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
})

export default Page