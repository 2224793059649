import React from "react"
import {VictoryAxis, VictoryBar, VictoryChart, VictoryContainer, VictoryStack, VictoryTheme} from "victory";

type props = {
    market: any
}
const Cork = ({market}: props) => {
    const row1 = [
        {col: 1, val: parseInt(market?.stats?.daily_cork), fill: '#d5e8d4'},
        {col: 2, val: market?.condition?.cork_k, fill: '#9bc1f8'},

    ];

    const row2 = [
        {col: 2, val: market?.condition?.cork_t, fill: '#dbe8fb'},

    ];
    return <div>
        Пробка
        <VictoryChart
            maxDomain={{x: 2}}
            theme={VictoryTheme.material}
            containerComponent={<VictoryContainer className="myProductChart"/>}>
            <VictoryAxis tickValues={[1, 2]} tickFormat={["", ""]}/>
            <VictoryStack style={{
                data: {
                    fill: ({datum}) => datum.fill,
                    fillOpacity: 0.7,
                    strokeWidth: 3
                },
                labels: {
                    fontSize: 25,
                    fill: "white"
                }
            }}>
                <VictoryBar barRatio={2} data={row1} x="col" y="val"
                            labels={({datum}) => `${datum.val}`}/>
                <VictoryBar barRatio={2} data={row2} x="col" y="val"
                            labels={({datum}) => datum.val ? `${datum.val}` : ''}/>
            </VictoryStack>
        </VictoryChart>
        <div><span style={{backgroundColor: "#d5e8d4"}}>&nbsp;</span> Продано за сутки</div>
        <div><span style={{backgroundColor: "#9bc1f8"}}>&nbsp;</span> Остаток в лотке</div>
        <div><span style={{backgroundColor: "#dbe8fb"}}>&nbsp;</span> Остаток в киоске</div>

    </div>
}

export default Cork