import React from "react"
import { observer } from "mobx-react-lite"
import { Col, Nav, Row, Tab } from "react-bootstrap"
import Users from "./firm/user"
import Entitys from "./firm/entitys"
import BrandCard from "./firm/brand"

const Firm = observer(() => {
    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
            <Row>
                <Col sm={3}>
                    <Nav variant="pills" className="flex-column mb-3">
                        <Nav.Item>
                            <Nav.Link eventKey="1">
                                Сотрудники
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="2">
                                Автомобили
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="3">
                                Организации
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="4">
                                Общие
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="1">
                            <Users/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="2">
                            Автомобили
                        </Tab.Pane>
                        <Tab.Pane eventKey="3">
                            <Entitys/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="4">
                            <BrandCard/>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
})

export default Firm