import React, {useEffect} from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../../../RootStateContext"
import MarketCard from "./MarketCard";


const Markets = observer(() => {
    const {marketsStore, firmsStore, priceListStore, configurationStore} = useRootStore()
    const {markets, groups, loadMarkets, loadGroups, updateMarket} = marketsStore
    const {entitys, loadEntitys} = firmsStore
    const {priceLists, getPriceLists} = priceListStore
    const {personalizations, loadPersonalizations, cities, loadCities} = configurationStore


    useEffect(()=> {
        loadMarkets()
        loadGroups()
        loadEntitys()
        getPriceLists()
        loadPersonalizations()
        loadCities()
    }, [])

    return (
        <>
            {markets?.map(m => <MarketCard
                key={m.id}
                market={m}
                groups={groups}
                entities={entitys}
                priceLists={priceLists}
                personalizations={personalizations}
                cities = {cities}
                update = {updateMarket}
            />)}
        </>
    )
})

export default Markets
