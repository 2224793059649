import React from "react"
import { observer } from "mobx-react-lite"
import { Col, Nav, Row, Tab } from "react-bootstrap"
import Personalization from './configurations/Personalization'
import Pricelists from './configurations/Pricelist'
import MarketGroups from "./configurations/MarketGroups";
import Markets from "./configurations/Markets";

const Configurations = observer(() =>{
    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
            <Row>
                <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="1">
                                Прайс листы
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="2">
                                Персонализация
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="3">
                                Наличные
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="5">
                                Технические
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="6">
                                Группы
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="7">
                                Киоски
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="1">
                            <Pricelists/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="2">
                            <Personalization/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="3">
                            Наличные
                        </Tab.Pane>
                        <Tab.Pane eventKey="5">
                            Технические
                        </Tab.Pane>
                        <Tab.Pane eventKey="6">
                            <MarketGroups/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="7">
                            <Markets/>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
})

export default Configurations