import {observable, action, makeAutoObservable, runInAction} from "mobx"
import {getUsers, createUser, createUserPassword, createUserStatus, createUserRole} from '../api/users';

export class UserStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable users?: any[]
    @observable userCreateResult?: any[]

    @action getUsers = () => {
        getUsers()
            .then( data => {
                runInAction(() => {
                    this.users = data
                })
            })
    }

    @action createUser = (email: string, password: string, role: string, name: string) => {       
        createUser(email, password, role, name)
            .then( data => {              
                this.userCreateResult = data
                this.getUsers()
            })
    }

    @action updateUser = (userId: number, password: string, status: string, role: string) => {
        // createUserPassword(userId, password)
        //     .then( data => {
        //         console.log(data);
        //     })

        // createUserStatus(userId, status)
        //     .then( data => {
        //         console.log(data);
        //     })
        
        // createUserRole(userId, role)
        //     .then( data => {
        //         console.log(data);
        //     })

        Promise.all([
            createUserPassword(userId, password)
                .then( data => {
                    //console.log(data)
                }),
            createUserStatus(userId, status)
                .then( data => {
                    //console.log(data)
                }),
            createUserRole(userId, role)
                .then( data => {
                    //console.log(data)
                })
        ]).then(() => {
            //console.log('all done')
            this.getUsers()
        })
    }
}
