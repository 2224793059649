import React, {useEffect, useState} from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../../../RootStateContext"
import {Col, Row, Card, Form, Button, Accordion, Container} from "react-bootstrap"
import MarketGroupCard from "./MarketGroupCard";


const MarketGroups = observer(() => {
    const {marketsStore} = useRootStore()
    const {groups, loadGroups, createGroup} = marketsStore

    useEffect(
        () => {
            loadGroups()
        },[])

    const [name, setName] = useState('')
    const [errors, setErrors ] = useState({name: ''})



    const handleCreateForm = (e: React.FormEvent) => {
        e.preventDefault()
        const newErrors = findFormErrors()

        if (!Object.values(newErrors).every(x => (x === null || x === ''))) {
            setErrors(newErrors)
        } else {
            setErrors({name: ''})
            createGroup(name)
        }
    }

    const findFormErrors = () => {
        const newErrors = {name: ''}
        setErrors(newErrors)
        if ( !name || name === '' ) newErrors.name = 'Имя не может быть пустым'
        return newErrors
    }





    return (
        <>
            <Card>
                <Card.Body>
                    <Form onSubmit={handleCreateForm}>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridGroup">
                                <Form.Label>Название</Form.Label>
                                <Form.Control value={name} onChange={e => setName(e.target.value)} required isInvalid={ !!errors.name }/>
                                <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Button variant="primary" type="submit">
                            Добавить
                        </Button>
                    </Form>
                </Card.Body>
            </Card>

            {groups?.map(g => {
                return <MarketGroupCard key={g.id} id={g.id} name={g.name}/>
            })}

        </>
    )
})

export default MarketGroups