import React from "react"
import {VictoryAxis, VictoryBar, VictoryChart, VictoryContainer, VictoryStack, VictoryTheme} from "victory";

type props = {
    market: any
}
const Card = ({market}: props) => {
    const row1 = [
        {col: 1, val: parseInt(market?.stats?.daily_card), fill: '#d5e8d4'},
        {col: 2, val: market?.condition?.cards, fill: '#9bc1f8'},

    ];


    return <div>
        Карты
        <VictoryChart
            maxDomain={{x: 2}}
            theme={VictoryTheme.material}
            containerComponent={<VictoryContainer className="myProductChart"/>}>
            <VictoryAxis tickValues={[1, 2]} tickFormat={["", ""]}/>
            <VictoryBar barRatio={2} data={row1} x="col" y="val"
                labels={({datum}) => `${datum.val}`}
                style={{
                    data: {
                        fill: ({datum}) => datum.fill,
                        fillOpacity: 0.7,
                        strokeWidth: 3
                    },
                    labels: {
                        fontSize: 25,
                        fill: "white"
                    }
                }}/>

        </VictoryChart>
        <div><span style={{backgroundColor: "#d5e8d4"}}>&nbsp;</span> Продано за сутки</div>
        <div><span style={{backgroundColor: "#9bc1f8"}}>&nbsp;</span> Остаток в лотке</div>

    </div>
}

export default Card