import React from "react";
import { observer } from "mobx-react-lite";
import Styles from './MarketsToolbar.module.scss'
import {useRootStore} from "../../RootStateContext";

const Toolbar = observer(() =>{
    const {marketsStore} = useRootStore()
    const {currentGroupId, setCurrentGroupId, resetFilter, groups} = marketsStore;
    return (
        <div className={Styles.wrapper + ' toolbar'}>
            {groups.map( group => (
                <span key={group.id} onClick={e => setCurrentGroupId(group.id)} className={`${group.id === currentGroupId ? Styles.active : ''}`}>
                    {group.name}
                </span>
            ))}
            <span onClick={ e => resetFilter()}>Все</span>
        </div>
    )
})

export default Toolbar;