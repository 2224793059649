import api from './api'

export const loadCities = async () => {
    const response = await api.get(
        'city',
        {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
    )
    return response.data
}