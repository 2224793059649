import {observable, action, makeAutoObservable, runInAction} from "mobx"
import {loadEntitys, createEntity, updateEntity, deleteEntity} from '../api/entity';

export type Entity = {
    id: number,
    name: string,
    inn: string,
    sbp?: {
        login: string,
        password: string,
        elplatPhone: string,
        orgId: number
    },
    toCan?: {
        email: string,
        secret_key: string
    }
}
export class FirmStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable entitys: Entity[] = []

    @action loadEntitys = () => {
        loadEntitys()
            .then( data => {
                runInAction(() => {
                    this.entitys = data
                })
            })
    }

    @action createEntity = (name: string, inn: string) => {
        createEntity(name, inn)
            .then( data => {              
                //this.getFirms()
            })
    }

    @action updateEntity = (entity: Entity) => {
        updateEntity(entity)
            .then(data => {
                runInAction(() => {
                    this.entitys = this.entitys.map( e => {
                        if (data.id === e.id) {
                            return data
                        }
                        return e;
                    })
                })
            })
    }

    @action deleteEntity = (firmId: number) => {
        deleteEntity(firmId)
            .then( data => { 

            })
    }
}
