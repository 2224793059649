import api from './api'
import {Entity} from "../stores/FirmStore";

export const loadEntitys = async () => {
    const response = await api.get(
        'firm/entity', 
        {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
    )
    return response.data
}

export const createEntity = async (name: string, inn: string) => {
    const response = await api.post(
        'firm/entity', 
        {name, inn},
        {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
    )
    return response.data
}

export const updateEntity= async (entity: Entity) => {
    const response = await api.put(
        `firm/entity/${entity.id}`,
        entity,
        {headers: {Authorization: `Bearer ${localStorage.getItem('authToken')}`}}
    )
    return response.data
}

export const deleteEntity = async (firmId: number) => {
    const response = await api.delete(
        `firm/entity/${firmId}`, 
        {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
    )
    return response.data
}