import React, {useState} from "react"
import {Button, Col, Container, Form, ListGroup, Row, Tab, Tabs, Modal} from "react-bootstrap"
import {getReportData} from "../../api/report";
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
const Page = () => {
    let now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    const [toDate, setToDate] = useState(now.toISOString().slice(0,16))

    now.setDate(now.getDate() - 1);
    const [fromDate, setFromDate] = useState(now.toISOString().slice(0,16))

    const [show, setShow] = useState(false);
    const [reportList, setReportList] = useState([
        {key: "sales", label: "Продажи", active: false}
    ])

    const [currentReport, setCurrentReport] = useState('')

    const [reportRows, setReportRows] = useState<any>([])
    const [reportCols, setReportCols] = useState<any>([])

    const onSelectReport = (key: string) => {
        setReportList(reportList.map(r => {
            r.active = r.key === key
            if (r.active) {
                setCurrentReport(r.key)
            }
            return r
        }))
        setShow(true);
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const generateReport = () => {
        setShow(false)
        const columns: GridColDef[] = [
            {field: 'kiosk_number', headerName: 'Номер'},
            {field: 'address', headerName: 'Адрес'},

            {field: 'litrs_real', headerName: 'Вода наличный расчет л.'},
            {field: 'litrs_virt', headerName: 'Вода по рублевым картам л.'},
            {field: 'litrs_contractor', headerName: 'Вода по литровым картам л.'},
            {field: 'litrs_bank', headerName: 'Вода по банковским картам л.'},
            {field: 'litrs_service', headerName: 'Вода хоз нужды'},
            {field: 'litrs', headerName: 'Вода ИТОГО'},


            /*{field: 'litrs_before', 'headerName': 'Остаток воды на начало периода'},
            {field: 'refill', 'headerName': 'Заправлено воды за период'},
            {field: 'litrs_after', 'headerName': 'Остаток воды на конец периода'},*/

            {field: 'litrs_real_price', headerName: 'Выручка за воду нал, руб'},
            {field: 'litrs_virt_price', headerName: 'Выручка за воду по фирменным картам, руб'},
            {field: 'litrs_bank_price', headerName: 'Выручка за воду по банковским картам, руб'},
            {field: 'litrs_price', headerName: 'Итого выручка за воду, руб'},

            {field: 'bottles_count', headerName: 'Тара, шт. наличный расчет'},
            {field: 'bottles_virt', headerName: 'Тара, шт. по фирменным картам'},
            {field: 'bottles_bank', headerName: 'Тара, шт. по банковским картам'},
            {field: 'bottles', headerName: 'Тара, шт. ИТОГО'},


            {field: 'bottles_real_price', headerName: 'Выручка за тару нал, руб'},
            {field: 'bottles_virt_price', headerName: 'Выручка за тару по фирменным картам, руб'},
            {field: 'bottles_bank_price', headerName: 'Выручка за тару по банковским картам, руб'},
            {field: 'bottles_price', headerName: 'Итого выручка за тару, руб'},

            {field: 'cork_real', headerName: 'Пробки, шт. наличный расчет'},
            {field: 'cork_virt', headerName: 'Пробки, шт. по фирменным картам'},
            {field: 'cork_bank', headerName: 'Пробки, шт. по банковским картам'},
            {field: 'cork', headerName: 'Пробки, шт. ИТОГО'},


            {field: 'cork_real_price', headerName: 'Выручка за пробки нал, руб'},
            {field: 'cork_virt_price', headerName: 'Выручка за пробки по фирменным картам, руб'},
            {field: 'cork_bank_price', headerName: 'Выручка за пробки по банковским картам, руб'},
            {field: 'cork_price', headerName: 'Итого выручка за пробки, руб'},



            {field: 'real_money', headerName: 'Выручка нал'},
            {field: 'virtual_money', headerName: 'Выручка по фирменным картам'},
            {field: 'bank_money', headerName: 'Выручка по банковским картам'},
            {field: 'money', headerName: 'Выручка ИТОГО'},

            {field: 'card_count', headerName: 'Продано карт, шт'},
            {field: 'card_price', headerName: 'Продано карт, руб'},
            {field: 'update_card', headerName: 'Внесено на карты, руб.'},
            {field: 'prepayment', headerName: 'Итого Авансов, руб'},

            {field: 'rest_0', headerName: 'Невыданная (но востребованная сдача), руб.'},
            {field: 'rest_1', headerName: 'Невостребованная сдача, руб.'}
        ]
        setReportCols(columns)
        getReportData(currentReport, fromDate, toDate)
            .then( data => {
                const rows: GridRowsProp = data
                setReportRows(rows)
            })

        //setReportRows(data)
    }

    return <>
        <Container fluid >
            <Row >
                <ListGroup as="ul">
                    {reportList.map(r => <ListGroup.Item key={r.key} active={r.active} action onClick={() => {
                        onSelectReport(r.key)
                    }}>{r.label}</ListGroup.Item>)}
                </ListGroup>

            </Row>
            {reportRows.length > 0 &&
                <Row style={{height:"500px"}}>
                    <DataGrid columns={reportCols} rows={reportRows}/>
                </Row>
            }
        </Container>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body><Form>
                <Form.Group>
                    <Form.Label>От</Form.Label>
                    <Form.Control type="datetime-local" value={fromDate} onChange={ e => {setFromDate(e.target.value)}}></Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>До</Form.Label>
                    <Form.Control type="datetime-local" value={toDate} onChange={ e => {setToDate(e.target.value)}}></Form.Control>
                </Form.Group>
                <Form.Group>

                </Form.Group>
            </Form></Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Отмена
                </Button>
                <Button variant="primary" type="submit" onClick={generateReport}>
                    Сформировать отчет
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}

export default Page