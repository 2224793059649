import api from './api'
import {log} from "util";
import PersonalizationCard from "../components/managment/managmentComponents/configurations/PersonalizationCard";

export const loadPersonalizations = async () => {
    const response = await api.get(
        'ui-template',
        {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        }
    )
    return response.data
}

export const createPersonalisation = async (name: string, logotype: File) => {
    let requestData = new FormData()
    requestData.append('name', name)
    requestData.append('logotype', logotype)

    const response = await api.post(
        'ui-template',
        requestData,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                'Content-Type': 'multipart/form-data'
            }
        }
    )
    return response.data
}

export const updatePersonalization = async (id: number, name: string, logotype?: File) => {
    let requestData = new FormData()
    requestData.append('name', name)
    if (logotype !== undefined) {
        requestData.append('logotype', logotype)
    }


    const response = await api.post(
        `ui-template/${id}`,
        requestData,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                'Content-Type': 'multipart/form-data'
            }
        }
    )

    return response.data
}

export const deletePersonalization = async (id: number) => {
    const response = await api.delete(
        `ui-template/${id}`,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                'Content-Type': 'multipart/form-data'
            }
        }
    )

    return response.data
}