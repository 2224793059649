import React from "react"
import getSymbolFromCurrency from 'currency-symbol-map'
import {VictoryBar, VictoryChart, VictoryAxis, VictoryContainer, VictoryTheme} from 'victory'

type coinProps = {
    market: any
}

const CoinCassette = ({market}: coinProps) => {
    let currentCurrency;
    market?.condition?.devices?.billvalidator.data.version.currency === 'RUR'
        ? currentCurrency = 'RUB' 
        : currentCurrency = market?.condition?.devices?.billvalidator.data.version.currency;

    const currency = getSymbolFromCurrency(currentCurrency)

    const colors = ["#2B3E50", "#4F2B50", "#503D2B", "#2B502B"]
    let coinSumTube = 0
    let index = 0
    let data: any[] = []
    let tickValues: number[] = []
    let tickFormat: string[] = [] //["1 руб.", "2 руб.", "5 руб.", "10 руб."]
    Object.keys(Object.assign({}, market?.condition?.devices?.coin.data.level)).forEach((coin) => {
        let countCoins = parseInt(market?.condition?.devices?.coin.data.level[coin])
        if (countCoins > 0) {
            data.push({
                quarter: index + 1,
                count: countCoins,
                fill: colors[index],
                label: `${countCoins} шт.`
            })
            let value = parseInt(coin) / 100
            coinSumTube += value * market?.condition?.devices?.coin.data.level[coin]
            tickValues.push(index+1)
            tickFormat.push(`${parseInt(coin)/100} ${currency}`)
            index++
        }

    })

    if (data.length === 0) {
        return <></>
    }

    return (
        <div className="details_block details_block--sm">
            <h4 className="text-center details__header">Монеты в кассете</h4>
            <VictoryChart
                domainPadding={{ x: 5 }}
                maxDomain={{ x: 4 }}
                theme={VictoryTheme.material}
                containerComponent={<VictoryContainer className="myChart"/>}
            >
                <VictoryAxis
                    tickValues={tickValues}
                    tickFormat={tickFormat}
                    style={{tickLabels: {fontSize: 20}}}
                />
                <VictoryBar
                    data={data}
                    x="quarter"
                    y="count"
                    barRatio={1.2}
                    //barWidth={50}
                    style={{
                        data: {
                            fill: ({ datum }) => datum.fill,
                            stroke: ({ datum }) => datum.fill,
                            fillOpacity: 0.7,
                            strokeWidth: 3
                        },
                        labels: {
                            fontSize: 20
                        }
                    }}
                />
            </VictoryChart>

            <p className="text-center text--bold text--blue">Итого: {coinSumTube} {currency}</p>
        </div>
    )
}

export default CoinCassette