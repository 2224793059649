import React from 'react'
import Menu from "./Menu"
import {observer} from "mobx-react-lite"

const Header = observer(() => {
    return (
        <>    
            <Menu/>
        </>
    )
})

export default Header