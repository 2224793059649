import React from "react"
import { observer } from "mobx-react-lite"
import { Col, Nav, Row, Tab } from "react-bootstrap"

const TaskManager = observer(() =>{
    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
            <Row>
                <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="1">
                                Планировщик задач
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="1">
                            Планировщик задач
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
})

export default TaskManager