import React, {useEffect, useState} from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../../../RootStateContext"
import {Col, Row, Card, Form, Button} from "react-bootstrap"

const Brand = observer(() => {
    const {brandStore} = useRootStore()
    const {brand, getBrand, updateBrand} = brandStore

    const [name, setName] = useState('')
    const [file, setFile] = useState()
    const [fileName, setFileName] = useState('')
    const [extError, setExtError] = useState('')
    const [sizeError, setSizeError] = useState('')

    useEffect(() => {
        getBrand()
    }, [])

    const handleSubmit = (event: any) => {        
        event.preventDefault()
        event.stopPropagation()
        let bodyFormData = new FormData()
        bodyFormData.append('name', name)
        bodyFormData.append('logotype', file!, fileName)        

        updateBrand(name, file!)
    }

    const onChangeFile = (e:any) => {
        setExtError('')
        setSizeError('')

        if (e.target.files[0].type !== 'image/png') {
            setExtError('Изображение должно именть формат PNG.')
        }

        if (e.target.files[0].size / 1024 >= 100) {
            setSizeError('Размер файла не должен превышать 100кб.')
        }

        if(extError || sizeError) {
            return
        } else {
            let files = e.target.files;
            setFile(files[0])
            setFileName(files[0].name)
        }
    }

    return (
        <>
            <Card style={{ width: '18rem' }} className='mb-3'>
                <Card.Img variant="top" src={'data:image/png;base64,' + brand?.logotype} />
                <Card.Body>
                    <Card.Title>{brand?.name}</Card.Title>
                </Card.Body>
            </Card>


            <Card>
                <Card.Body>
                    <Form onSubmit={handleSubmit} autoComplete="off">
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label htmlFor="brandName">Название</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="brandName"
                                    size="sm"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label htmlFor="brandLogo">Логотип</Form.Label>
                                <Form.Control
                                    type="file"
                                    id="brandLogo"
                                    size="sm"
                                    onChange={(e) => onChangeFile(e)}
                                />
                                <Form.Text className="text-muted">
                                    В формате PNG, не более 100кб.
                                </Form.Text>
                            </Form.Group>
                        </Row>
                        
                        <Row>
                            <Col sm={2}>
                                <Button 
                                    variant="primary" 
                                    type="submit" 
                                    disabled={(extError.length > 0) || (sizeError.length > 0) || (name.length === 0) || (fileName.length === 0)}
                                >
                                    Обновить
                                </Button>
                            </Col>
                            <Col>
                                <div className="errors" style={{fontSize: '13px', color: 'red'}}>
                                    {extError}<br/>{sizeError}    
                                </div>                    
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </>
    )
})

export default Brand