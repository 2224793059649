import api from './api'

export const priceList = async (name: string, water_1: number, water_2: number, water_3: number, water_price: number, bottle_price: number, cork_price: number, card_price: number, adjusted_pour: boolean, active: boolean) => {
    const response = await api.post(
        'pricelist', 
        {name, water_1, water_2, water_3, water_price, bottle_price, cork_price, card_price, adjusted_pour, active},
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            }
        }
    )
    return response.data
}

export const getPriceLists = async () => {
    const response = await api.get(
        'pricelist', 
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            }
        }
    )
    return response.data
}

export const deletePriceLists = async (id: number) => {
    const response = await api.delete(
        `pricelist/${id}`, 
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            }
        }
    )
    return response.data
}