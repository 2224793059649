import {observable, action, makeAutoObservable, runInAction} from "mobx"
import {priceList, getPriceLists, deletePriceLists} from '../api/priceList'

export interface PriceList {
    id: number,
    active: boolean,
    adjusted_pour: boolean,
    bottle_price: number,
    cork_price: number,
    markets_count: number,
    name: string,
    water_1: number,
    water_2: number,
    water_3: number,
    water_price: number
}

export class PriceListStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable priceLists: PriceList[] = [];

    @action priceList = (name: string, water_1: number, water_2: number, water_3: number, water_price: number, bottle_price: number, cork_price: number, card_price: number, adjusted_pour: boolean, active: boolean) => {
        priceList(name, water_1, water_2, water_3, water_price, bottle_price, cork_price, card_price, adjusted_pour, active)
            .then( data => {              
                this.getPriceLists()
                       
            })
    }

    @action getPriceLists = () => {
        getPriceLists()
            .then( data => {
                runInAction(() => {
                    this.priceLists = data
                })

            })
    }

    @action deletePriceLists = (id: number) => {
        deletePriceLists(id)
            .then(data => {
                this.getPriceLists()
            })
    }
}
