import React, {useState} from "react"
import { observer } from "mobx-react-lite"
import { Button, Card, Col, Form, Row, Accordion } from "react-bootstrap"
import {useRootStore} from "../../../../RootStateContext"

type PriceListProps = {
    data: {
        id: number,
        name: string,
        water_1: number,
        water_2: number,
        water_3: number,
        water_price: number,
        bottle_price: number,
        cork_price: number,
        adjusted_pour: boolean,
        markets_count: number
    }
}

const PriceListCard = observer(({data}: PriceListProps) => {
    const {priceListStore} = useRootStore()
    const {deletePriceLists} = priceListStore

    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <strong>{data.id}</strong>
                    &nbsp;
                    {data.name}
                </Accordion.Header>
                <Accordion.Body>
                    <Card key={data.id}>
                        <Card.Body>
                            <Card.Text>
                                Вода (объем 1) {data.water_1}
                            </Card.Text>
                            <Card.Text>
                                Вода (объем 2) {data.water_2}
                            </Card.Text>
                            <Card.Text>
                                Вода (объем 3) {data.water_3}
                            </Card.Text>
                            <Card.Text>
                                Вода за 1л {data.water_price}
                            </Card.Text>
                            <Card.Text>
                                Тара, руб {data.bottle_price}
                            </Card.Text>
                            {/* <Card.Text>
                                Карта, руб {data.water_price}
                            </Card.Text> */}
                            <Card.Text>
                                Пробка, руб {data.cork_price}
                            </Card.Text>
                            <Card.Text>
                                Свободный налив {data.adjusted_pour ? 'Да' : 'Нет'}
                            </Card.Text>

                            {
                                data.markets_count === 0 && 

                                <Button 
                                    variant='danger'
                                    onClick={() => deletePriceLists(data.id)}
                                >
                                    Удалить
                                </Button>
                            }
                        </Card.Body>
                    </Card>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
})

export default PriceListCard