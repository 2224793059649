import api from './api'

export const getReportData = async (key: string, from: string, to: string) => {
    const response = await api.get(
        `report/${key}`,
        {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
            params: {from, to}
        },
    )
    return response.data
}