import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {RootStateProvider} from "./RootStateContext"
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

ReactDOM.render(
  <React.StrictMode>
      <RootStateProvider>
          <App />
      </RootStateProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// serviceWorkerRegistration.register()
reportWebVitals()
