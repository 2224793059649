import {observable, action, makeAutoObservable, runInAction} from "mobx"
import {getBrand, updateBrand} from '../api/brand'

interface Brand {
    logotype: string,
    name: string
}

export class BrandStore {

    constructor() {
        makeAutoObservable(this)
    }


    @observable brand?: Brand

    @action getBrand = () => {
        getBrand()
            .then(data => {
                runInAction(() => {
                    this.brand = data
                })

            })
    }

    // @action updateBrand = (formData: FormData) => {
    //     updateBrand(formData)
    //         .then( data => {              
    //             console.log('createFirms', data)     
    //             this.getBrand()
    //         })
    // }
    @action updateBrand = (name: string, logotype: File) => {
        updateBrand(name, logotype)
            .then( data => {              
                this.getBrand()
            })
    }
}
