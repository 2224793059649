import React from 'react';
import {useRootStore} from "../RootStateContext";
import Styles from './LoginForm.module.scss'
import {observer} from "mobx-react-lite";

const LoginForm = observer(() => {
    const {authStore} = useRootStore()
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        e.stopPropagation()
        authStore.login(email, password)
    }

    return (
        <div className={Styles.loginFormWrapper}>
            <form onSubmit={handleSubmit}>
                <div className={Styles.loginFormErrors}>{authStore.errorMessages?.map( message => (<>{message}</>))}</div>
                <div>
                    <div className="w_row">
                        <input className="w_input" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="E-mail"/>
                    </div>
                    <div className="w_row">
                        <input className="w_input" type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Пароль"/>
                    </div>
                    <div className="w_row">
                        <input className="w_button" type="submit" value="Войти"/>
                    </div>
                </div>
            </form>
        </div>
    )
})

export default LoginForm;