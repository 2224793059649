import React from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../../RootStateContext"

const Sensors = observer(() => {
    const {marketsStore} = useRootStore()
    const {currentMarket} = marketsStore

    return (
        <div className="details_block details_block--wide">
            <h4 className="text-center details__header">Датчики</h4>
            <table className="detailsTable">
                <tbody>
                    {/* <tr>
                        <td colSpan={2}>Термодатчик</td>
                        <td colSpan={2}>{currentMarket?.condition?.devices?.termo?.status}</td>
                    </tr> */}
                    <tr>
                        <td colSpan={2}>Входная дверь</td>
                        <td colSpan={2}>{currentMarket?.condition?.devices?.hwdriver?.data?.door_open ? 'Открыта' : 'Закрыта'}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>Датчик уровня</td>
                        <td colSpan={2}>{currentMarket?.condition?.devices?.hwdriver?.data?.water_low ? 'Вверху' : 'Внизу'}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>Температура</td>
                        <td colSpan={2}>{currentMarket?.condition?.devices?.termo?.data?.temperature.toFixed()}&deg;</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>Свет вкл/выкл</td>
                        <td colSpan={2}>{currentMarket?.condition?.devices?.hwdriver?.data?.sunset}/{currentMarket?.condition?.devices?.hwdriver.data.sunrise}</td>
                    </tr>
                    {/* <tr>
                        <td colSpan={1}>Диск</td>
                        <td colSpan={1}>{currentMarket?.condition?.disk?.used?.toFixed(1)}%</td>
                        <td colSpan={1}>ЦПУ</td>
                        <td colSpan={1}>{currentMarket?.condition?.cpu?.t?.toFixed()}&deg;</td>
                    </tr> */}
                </tbody>
            </table>
        </div>
    )
})

export default Sensors