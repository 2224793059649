import {observable, action, makeAutoObservable, runInAction} from "mobx"
import {authApi} from '../api/auth'

export interface User {
    id: number,
    email: string
}

export class AuthStore {

    constructor() {
        makeAutoObservable(this)
    }

    @observable isAuth?: boolean
    @observable user?: User
    @observable email?: string;
    @observable errorMessages: string[] = []
    @observable token?: string

    @action login = (email: string, password: string) => {
        this.errorMessages = []
        authApi
            .login(email, password)
            .then( token => {
                runInAction(() => {
                    localStorage.setItem('authToken', token)
                    this.token = token
                    this.isAuth = true
                    this.errorMessages = []
                    this.email = email
                })
            })
            .catch( e => {
                if (e.response.data.message !== undefined) {
                    this.addErrorMessage(e.response.data.message)
                } else {
                    this.addErrorMessage(e.message)
                }
            })
    }

    @action logout = () => {
        authApi
            .logout()
            .then( data => {
                localStorage.removeItem('authToken')
                runInAction(() => {
                    this.isAuth = false
                    this.user = undefined
                })

            })
            .catch( e => {
                console.log(e)
            })

    }

    @action authenticate = () => {
        this.isAuth = false;
        authApi
            .authenticate()
            .then( response => {
                if (response.data.isAuth === true) {
                    runInAction( () => {
                        this.isAuth = true
                        this.user = response.data.user
                    })
                } else {
                    this.isAuth = false
                }
            })
    }

    @action addErrorMessage = (message: string) => {
        this.errorMessages.push(message)
        // console.log(this.errorMessages)
    }
}
